// src/components/landing/section/Hero.js
import React from "react";
import "../style/hero.scss";

const Hero = () => {
    return (
        <div
            className="hero"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/image1.jpeg)`,
            }}
        >
            <div className="hero-overlay">
                <h1 id="hero-title">Welcome to RJ Travel Trans</h1>
            </div>
        </div>
    );
};

export default Hero;
