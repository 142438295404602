// src/components/landing/main/Navbar.js
import React, { useState } from "react";
import "../style/navbar.scss";
import { FaBars, FaGlobe } from "react-icons/fa";

const Navbar = () => {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const [language, setLanguage] = useState("EN");
    const [darkMode, setDarkMode] = useState(false);

    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const categories = {
        EN: ["Services", "Vehicles", "Apartments"],
        FR: ["Services", "Véhicules", "Appartements"],
        SR: ["Usluge", "Vozila", "Apartman"],
    };

    const cycleLanguage = () => {
        if (language === "EN") setLanguage("FR");
        else if (language === "FR") setLanguage("SR");
        else setLanguage("EN");
    };

    const toggleDarkMode = () => {
        setDarkMode((prevMode) => !prevMode);
        document.body.classList.toggle("dark-mode", !darkMode);
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img
                    src={"/logo.png"}
                    alt="RJ Travel Trans"
                    style={{
                        width: "24px",
                        height: "auto",
                        scale: "2.5",
                        objectFit: "cover",
                        backgroundColor: "black",
                        borderRadius: "100%",
                    }}
                />
            </div>
            <div className="navbar-toggle" onClick={toggleNavbar}>
                <FaBars size={24} style={{ color: "gold" }} />
            </div>
            <div className={`navbar-content ${isNavbarOpen ? "open" : ""}`}>
                <div className="navbar-categories">
                    {categories[language].map((category, index) => (
                        <a
                            href={`#${category.toLowerCase()}-section`}
                            key={index}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            {category}
                        </a>
                    ))}
                </div>
                <div className="navbar-actions">
<button className="contact-button" onClick={() => window.location.href = "#contact"}>Contact Us</button>
                    <button className="language-button" onClick={cycleLanguage}>
                        <FaGlobe style={{ color: "skyblue" }} />
                        <span className="language-text">{language}</span>
                    </button>
                    <button
                        className="dark-mode-button"
                        onClick={toggleDarkMode}
                    >
                        {darkMode ? "🌙" : "☀️"}
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
