import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs"; // Import the new phone icon
import { SiWhatsapp, SiViber } from "react-icons/si"; // Import WhatsApp and Viber icons
import "../style/footer.scss"; // Import the CSS file for styling

const Footer = () => {
    return (
<footer id="contact" className="footer">
            <hr className="footer-line" />
            <div className="footer-social">
                <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaFacebook size={30} />
                </a>
                <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaInstagram size={30} />
                </a>
            </div>
            <div className="footer-contact">
                <a
                    href="https://wa.me/33627560340"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <SiWhatsapp size={30} />
                </a>
                <a href="viber://call">
                    <SiViber size={30} />
                </a>
                <a href="tel:+33627560340" className="footer-phone">
                    <BsTelephone size={30} />{" "}
                    {/* Updated the phone icon here */}
                    <span>+336 275 603 40</span>
                </a>
            </div>
        </footer>
    );
};

export default Footer;
