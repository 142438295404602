// src/components/landing/section/Services.js
import React from "react";
import "../style/services.scss";

const Services = () => {
    return (
        <div id="services-section" className="services-section">
            <div className="services-text">
                <h2 className="services-heading">Our Services</h2>
                <h1 className="services-title">Explore our Services</h1>
                <p className="services-description">
                    We offer passenger transportation services from all three
                    Parisian airports to desired addresses in Paris and the
                    surrounding region. From Charles de Gaulle and Orly
                    airports, we provide individual rides with fares ranging
                    from <strong>€50 to €70</strong>, depending on the destination. At Beauvais
                    airport, located 100km from Paris, we offer two distinct
                    services tailored to meet diverse needs. Our group
                    transportation option allows passengers to share vehicles
                    with others arriving around the same time or from the same
                    flight, with stops at multiple addresses. This service is
                    priced between <strong>€25 and €35</strong> per person for a one-way trip.
                    For travelers needing more personalized transport, we also
                    provide individual rides from Beauvais airport. This option
                    ensures direct and exclusive transportation to their desired
                    destination within Paris or the surrounding region.
                    Individual rides are available at a rate ranging from <strong>€100
                    to €140</strong> per trip, offering flexibility and convenience for
                    passengers arriving from various Balkan destinations such as
                    Belgrade, Skopje, Tirana, Zagreb, Zadar, and Dubrovnik.
                    Additionally, we offer individual rides to Disneyland,
                    Versailles, and Park Asterix starting at <strong>€70</strong>. Due to the
                    nature of airport arrivals and varying flight schedules,
                    group transportation services at Beauvais are not available
                    for every flight. Therefore, our individual transport option
                    serves as a reliable alternative, catering specifically to
                    the needs of our diverse clientele, primarily consisting of
                    travelers from the Balkan region. Whether you are arriving
                    for business or leisure, our transportation services ensure
                    a comfortable and hassle-free journey from any of Paris's
                    major airports to your specified destination. We strive to
                    accommodate your travel needs with professionalism and
                    efficiency, offering competitive pricing and flexible
                    options tailored to enhance your experience in Paris and its
                    surroundings.
                </p>
            </div>
            <div className="services-image">
                <img src="/assets/image2.jpg" alt="Services" />{" "}
                {/* Adjusted path for the image */}
            </div>
        </div>
    );
};

export default Services;
