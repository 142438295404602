// src/components/Landingpage.js
import React from 'react';
import Navbar from './landing/main/Navbar';
import Footer from './landing/main/Footer';
import Hero from './landing/section/Hero';
import Services from './landing/section/Services';
import Vehicles from './landing/section/Vehicles';
import Apartment from './landing/section/Apartment';

const Landingpage = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Services />
      <Vehicles />
      <Footer />
    </div>
  );
};

export default Landingpage;
