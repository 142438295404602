// src/components/landing/section/Vehicles.js
import React from "react";
import "../style/vehicles.scss";

const Vehicles = () => {
    return (
        <div id="vehicles-section" className="vehicles-section">
            <h2 className="vehicles-heading">Vehicles</h2>
            <h1 className="vehicles-title">Explore our Vehicles</h1>
            <div className="vehicles-images">
                <div className="vehicle">
                    <img src="/assets/image4.jpg" alt="Renault Trafic"/>
                    <h2 className="vehicle-name">Renault Trafic</h2>
                </div>

                <div className="vehicle">
                    <img src="/assets/image4.jpg" alt="Renault Trafic"/>
                    <h2 className="vehicle-name">Renault Trafic</h2>
                </div>
            </div>
            <p className="vehicles-description">
                We offer transportation services using our Renault Trafics,
                each of which accommodates up to 8 passengers along with the
                driver. They are well-equipped to ensure a comfortable and
                safe journey for our passengers. For larger groups or those
                requiring extra space, our Renault Trafics provide a spacious
                and relaxed travel experience. Our vehicles are maintained to
                high standards of cleanliness and reliability, ensuring a
                pleasant transportation experience to and from all Parisian
                airports and desired destinations in the city and surrounding
                areas.
            </p>
        </div>
    );
};

export default Vehicles;
